import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import MobileHeader from './MobileHeader'
import { useTranslation } from 'react-i18next'

function Header() {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [whiteMode, setWhiteMode] = useState(false)
  const [OpensubMenu, setOpneSubMenu] = useState(false)
  const [scrollY, setScrollY] = useState()

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset
    setScrollY(scrollPosition)
    if (window.location.pathname === '/' && scrollPosition < 200) {
      setWhiteMode(false)
    } else setWhiteMode(true)
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  useEffect(() => {
    if (window.location.pathname === '/') {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    } else setWhiteMode(true)
  }, [])

  return (
    <Container
      whiteMode={whiteMode}
      OpensubMenu={OpensubMenu}
      lang={i18n.language}
    >
      <MobileHeader />
      <Menu
        onMouseOver={() => {
          setWhiteMode(true)
        }}
        onMouseLeave={() => {
          location.pathname === '/' && scrollY < 300 && setWhiteMode(false)
        }}
        whiteMode={whiteMode}
      >
        <Logo
          src={
            whiteMode
              ? '/assets/icons/logo_black.svg'
              : '/assets/icons/logo_white.svg'
          }
          onClick={() => {
            navigate(`/`)
          }}
        />
        <MenuList whiteMode={whiteMode}>
          <Group
            onMouseOver={() => {
              setOpneSubMenu(true)
            }}
            onMouseLeave={() => {
              setOpneSubMenu(false)
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(`/about/introduce`)
              }}
              hilight={window.location.pathname.includes('about')}
            >
              ABOUT US
            </MenuItem>
            {OpensubMenu && (
              <SubMenuList>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/introduce`)
                  }}
                  hilight={window.location.pathname.includes('introuduce')}
                >
                  {t('header_about_introduce')}
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/news`)
                  }}
                  hilight={window.location.pathname.includes('news')}
                >
                  {t('header_about_news')}
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/members`)
                  }}
                  hilight={window.location.pathname.includes('members')}
                >
                  {t('header_about_members')}
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/history`)
                  }}
                  hilight={window.location.pathname.includes('history')}
                >
                  {t('header_about_history')}
                </SubMenuItem>
              </SubMenuList>
            )}
          </Group>
          <Group
            onMouseOver={() => {
              setOpneSubMenu(true)
            }}
            onMouseLeave={() => {
              setOpneSubMenu(false)
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(`/business/product`)
              }}
              hilight={window.location.pathname.includes('business')}
            >
              BUSINESS
            </MenuItem>
            {OpensubMenu && (
              <SubMenuList>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/business/product`)
                  }}
                  hilight={window.location.pathname.includes('product')}
                >
                  {t('header_business_products')}
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/business/tech`)
                  }}
                  hilight={window.location.pathname.includes('tech')}
                >
                  {t('header_business_tech')}
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/business/patent`)
                  }}
                  hilight={window.location.pathname.includes('patent')}
                >
                  {t('header_business_patent')}
                </SubMenuItem>
              </SubMenuList>
            )}
          </Group>
          <MenuItem
            onClick={() => {
              navigate(`/contact`)
            }}
            hilight={window.location.pathname.includes('contact')}
          >
            CONTACT
          </MenuItem>
          <MenuItem
            onClick={() => {
              const win = window.open(`https://spresto.oopy.io/`, '_blank')
              win.focus()
            }}
            hilight={window.location.pathname.includes('recruit')}
          >
            RECRUIT
          </MenuItem>
          <MenuItem
            onClick={() => {
              const win = window.open(
                'https://spresto.oopy.io/disclosure/',
                '_blank'
              )
              win.focus()
            }}
            hilight={window.location.pathname.includes('disclosure')}
          >
            DISCLOSURE
          </MenuItem>
        </MenuList>
        <Lang whiteMode={whiteMode}>
          <LangItem
            whiteMode={whiteMode}
            hilight={i18n.language === 'ko'}
            onClick={() => changeLanguage('ko')}
          >
            KO
          </LangItem>
          <LangItem whiteMode={whiteMode}>|</LangItem>
          <LangItem
            whiteMode={whiteMode}
            hilight={i18n.language === 'en'}
            onClick={() => changeLanguage('en')}
          >
            EN
          </LangItem>
        </Lang>
      </Menu>
    </Container>
  )
}

export default Header

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 1440px;
  height: ${(props) =>
    props.OpensubMenu ? (props.lang === 'ko' ? '325px' : '350px') : '99px'};

  transition: background-color 200ms ease-in-out;
  background-color: ${(props) => (props.whiteMode ? '#ffffff' : 'inherit')};
  z-index: 1003;
  @media all and (max-width: 768px) {
    background: none;
    width: 100%;
    z-index: 100;
  }
`

const Lang = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.whiteMode ? '#666666' : '#ffffff')};
`

const LangItem = styled.span`
  display: inline-block;
  padding: 40px 0;
  margin: 0 10px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  color: ${(props) =>
    props.whiteMode
      ? props.hilight
        ? ({ theme }) => theme.color.GPrimary
        : '#666666'
      : props.hilight
      ? '#ffffff'
      : '#ffffff'};

  opacity: ${(props) => (props.whiteMode ? '1' : props.hilight ? '1' : '0.5')};

  &:hover {
    color: ${({ theme }) => theme.color.GPrimary};
  }
`

const Menu = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'flex-start', 'space-between')}
  width: 100%;
  height: 99px;
  padding: 0 60px;
  margin: 0 auto;
  border-bottom: 1px solid #c6f4f8;
  /* ${(props) => (props.whiteMode ? '#C6F4F8' : '#ffffff')}; */
  @media all and (max-width: 768px) {
    display: none !important;
  }
`

const Logo = styled.img`
  display: block;
  background: transparent;
  width: 160px;
  margin-top: 30px;
  cursor: pointer;
`

const MenuList = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'flex-start')};
  color: ${(props) => (props.whiteMode ? '#666666' : '#ffffff')};
  transition: color 200ms ease-in-out;

  :last-child {
    margin-right: 0;
  }
`

const MenuItem = styled.span`
  display: inline-block;
  padding: 40px 0;
  margin: 0 60px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  color: ${(props) => props.hilight && '#00BAC7'};

  &:hover {
    color: ${({ theme }) => theme.color.GPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.color.GPrimary};
  }
`

const Group = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')}
`

const SubMenuList = styled.ul``
const SubMenuItem = styled.li`
  padding: 24px 0 0;
  text-align: center;
  color: ${({ theme }) => theme.color.TSecondary};
  transition: color 200ms ease-in-out;
  cursor: pointer;
  font-family: 'Noto Sans KR';
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;

  color: ${(props) => props.hilight && '#00BAC7'};

  :last-child {
    padding-bottom: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.color.GPrimary};
  }
`
